
(function ($) {
    if ($('#debug').length) {

        var debugButtons = '<button type="button" id="button-main-grid-switch" title="Toggle Grid"><i class="columns icon"></i></button>';
        debugButtons += '<button type="button" id="button-grids-switch" title="Toggle Grids"><i class="table icon"></i></button>';
        debugButtons += '<button type="button" id="button-images-switch" title="Toggle Images"><i class="image icon"></i></button>';
        $('body').prepend('<div class="debug-buttons">' + debugButtons + '</div>');

        $('#button-main-grid-switch').on('click', function() { $('#debug').toggle(); });
        $('#button-grids-switch').on('click', function() { $('body').toggleClass('show-grids'); });
        $('#button-images-switch').on('click', function() { $('body').toggleClass('show-images'); });
    }
    if ( !(('.dev' != window.location.hostname.substr(-4)) || ('localhost' != window.location.hostname.substr(-9))) ) {
        return;
    }
    var poll = 3000;
    var paths = ['/theme/marso/assets/css/marso.css','/theme/marso/assets/css/marso-mobile.css'];
    var queryString;

    $('.debug-buttons').prepend('<button type="button" id="button-css-refresh-start" title="Start CSS refresh"><i class="refresh icon"></i></button>');

    $('#button-css-refresh-start').on('click', function() {
        var path;
        var links = [];
        for (i = 0; i < paths.length; i++) {
            path = paths[i];
            $('link[rel=stylesheet]').each(function (i, element) {
                if (-1 < element.href.indexOf(path)) {
                    element.href = window.location.origin + path;
                    links.push(element);
                }
            });
        }
        //setInterval(
        //    function() {
        //        for (var i = links.length -1; i >= 0; i--) {
        //            refresh(links[i]);
        //        }
        //    },
        //    poll
        //);
        var timeout = 0;
        var len = links.length;
        for (var i = len -1; i >= 0; i--) {
            setTimeout(function(){
                var link = links.pop();
                setInterval(function(){
                    refresh(link);
                }, poll);
            },timeout);
            timeout += poll / len;
        }
    });

    function refresh(element) {
        $.ajax({
            url: element.href,
            async: true, // nothing works without this
            xhrFields: {
                withCredentials: true
            },
            ifModified: true,
            success: function (data, textStatus, jqXHR) {
                if (textStatus != 'notmodified') {
                    queryString = '?_' + new Date().getTime();
                    element.href = element.href.replace(/\?.*|$/, queryString);
                }
            }
        });
    }

})(jQuery);
